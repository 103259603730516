
.banner h1 {
  font-size: 55px;
  font-weight: bold;
  color: black;
}

.banner hr {
  border-top: 5px double black;
  width: 50%;
  margin: auto;
  
}
.banner{
  background-color: white;
  opacity: .8;
  width: 75%;
  justify-content: center;
  margin: auto;
  border-radius: 10px;
}

.banner p {
  color: black;
  font-size: 20px;
  padding: 1em;

}

.profile-img{
  border-radius: 50%;
  height: 20%;
  margin-top: 5em;
  
}
@media screen and (max-width: 940px){
  .tabs {
    display: none;
  }
  .profile-img{
    border-radius: 50%;
    height: 15%;
    margin-top: 5em;
  }
  .banner h1{
    font-size: 30px;
  }
}

@media screen and (max-width: 526px){
  .banner p{
    font-size: 12px;
  }

}
  


.mdl-layout-title{
  font-size:15px !important;
}





.header-navbar{
  background: #59C173;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #5D26C1, #a17fe0, #59C173);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #5D26C1, #a17fe0, #59C173); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.links{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 50%;
  margin: auto;
}

.links a{
  width: 100%;
  height: 100%;

}
.links-item{
  width: 20%;
}

.front-grid{
  background: #59C173;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #5D26C1, #a17fe0, #59C173);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #5D26C1, #a17fe0, #59C173); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}

.webgl_content{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

Iframe{
  border: none;
}

a {
  text-decoration-line: none;
  color: black;
}

.Nav_item a {
  color: black;

}

.subNav .subNav_item {
  margin-left: 3em;
  margin-top: 1em;
}

.Card_content{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Resume{
  margin-top: 3em;
  display: "flex";
}



.Resume_header{
  background:black; 
  display: block;
}

.Resume .Resume_title h2{
  font-size: 36px
}


.Resume_content {
  padding-left: 0.5em;
}

.Resume_summary .mdl-card__supporting-text{
  display: flex;
}

.Resume_left{
  width: 80%;
}


.Resume_work_main{
  display: block;
}


.Resume_work_item ul {
  list-style: disc;
}

.Resume_work_item ul li {
  display: list-item;
  max-width: 400px;
  overflow: visible;
  padding: 0;
  padding-left: 1.5em;
  margin-bottom: 1em;
  
}

.Resume_work_item ul li:last-child{
  margin-bottom: 0;
}



.Resume_work_main_item .year{
  max-width: 100px;
}

.Resume_work_main .Resume_work_main_item {
  display: flex;
  align-items: baseline;
  max-width: 600px;
  margin-bottom: 2em;
}
@media screen and (max-width: 764px){
  .webgl_content{
    margin-left: 0;
  }
  .Resume {
    display: flex;
  }

  .Resume_work_main .Resume_work_main_item {
    max-width: 288px;
    padding-left: 1em;
    flex-wrap: wrap;
  }

  .Resume_summary .mdl-card__supporting-text{
    max-width: 288px;
    flex-wrap: wrap;
  }


  
}


.Resume_work  .mdl-card__title {
  padding: 0;
  margin-bottom: 0.7em;
}



.Resume_content{
  display: flex;
  justify-content: space-between;  
}
.Resume_content .Resume_right{
  background: gray;
  width: 20%;
}


.Resume_content .Resume_right .Resume_skills p {
  margin-left: 0.3em;
}

.Card_about .mdl-card{
  background-color: wheat;
}

.mdl-card__supporting-text .mdl-card__title{
  padding-left: 0;
}

.Card_about {
  margin-top: 1em;
}

.mdl-card .Contact-item{
  display: flex;
  justify-content: space-between;
  padding-left: 0.3em;
  padding-right: 0.3em;
  margin-top: 0.5em;
}

@media screen and (max-width: 420px){
  .Contact-item{
    flex-wrap: wrap;
  }
}









